export const parseFormInitialState = ({
  state,
  module,
  enrollmentIntialState,
}) => {
  switch (module) {
    case "hope-patient":
      return Object.keys(state.patientEnrollment).length === 0
        ? {
            ...enrollmentIntialState,
            otherHospital: "",
            otherDiagnosis: "",
            contactedThrough: ["SMS"],
          }
        : state.patientEnrollment
    case "hope-diagnostic-subsidy":
      return Object.keys(state.diagnosticSubsidy).length === 0
        ? {
            dateOfXray: { month: { value: "" }, date: { value: "" }, year: "" },
            dateOfPulmonaryTest: {
              month: { value: "" },
              date: { value: "" },
              year: "",
            },
            ...enrollmentIntialState,
          }
        : state.diagnosticSubsidy
    case "patient":
      return Object.keys(state.patientEnrollment).length === 0
        ? {
            ...enrollmentIntialState,
            otherHospital: "",
            otherDiagnosis: "",
            contactedThrough: ["SMS"],
          }
        : state.patientEnrollment
    case "medicine-assistance":
      return Object.keys(state.medicineAssistance).length === 0
        ? {
            ...enrollmentIntialState,
            existingUserMAConsent: [],
          }
        : state.medicineAssistance
    case "medicine-availment":
      return Object.keys(state.medicineAvailment).length === 2
        ? {
            ...enrollmentIntialState,
            ...state.medicineAvailment,
          }
        : state.medicineAvailment
    case "financial-assessment-personal":
      return Object.keys(state.financialAssessmentPersonal).length === 0
        ? {
            ...enrollmentIntialState,
          }
        : state.financialAssessmentPersonal
    case "financial-assessment-financial":
      return state.financialDetails
  }
}

export const getFormValues = ({ state, module }) => {
  switch (module) {
    case "patient":
      return state.patientEnrollment
    case "diagnostic-subsidy":
      return state.diagnosticSubsidy
    case "medicine-assistance":
      return state.medicineAssistance
    case "financial-assessment":
      return { ...state.financialAssessmentPersonal, ...state.financialDetails }
    case "medicine-availment":
      return { ...state.medicineAvailment }
    default:
      return {}
  }
}
