import React from "react"
import { navigate } from "gatsby"
import classNames from "classnames"

import styles from "./utils/elements.module.scss"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const ProgressBar = ({ data }) => {
  const handleProgressClick = (event, link) => {
    event.preventDefault()
    navigate(link)
  }

  return (
    <div className="mb-4">
      <ul className="steps is-horizontal has-content-centered">
        {data?.map((step, index) => (
          <li
            className={classNames(`steps-segment`, {
              "is-active": step?.isActive,
            })}
          >
            {!step?.isActive && (
              <span className="steps-marker">
                {step?.type === "icon" && <FontAwesomeIcon icon={step?.icon} />}
                {step?.type !== "icon" && index + 1}
              </span>
            )}
            {(step?.isActive || index <= step?.activeIndex) && (
              <a
                href="#"
                onClick={event => handleProgressClick(event, step?.link)}
                className="steps-marker"
              >
                {step?.type === "icon" && <FontAwesomeIcon icon={step?.icon} />}
                {step?.type !== "icon" && index + 1}
              </a>
            )}
            {step?.label && (
              <div className="steps-content">
                <p className="is-size-6 has-text-weight-bold has-text-primary">
                  {step?.label}
                </p>
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default ProgressBar
