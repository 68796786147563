import * as Yup from "yup"
import {
  REQUIRED_MESSAGE,
  INVALID_EMAIL,
  INVALID_YEAR,
  INVALID_MOBILE_NUMBER,
  yearNow,
} from "services/validations"

export const hopePatientEnrollmentValidation = Yup.object().shape({
  firstName: Yup.string().required(REQUIRED_MESSAGE),
  lastName: Yup.string().required(REQUIRED_MESSAGE),
  emailAddress: Yup.string()
    .email(INVALID_EMAIL)
    .required(REQUIRED_MESSAGE),
  mobileNumber: Yup.string()
    .min(11, INVALID_MOBILE_NUMBER)
    .max(11, INVALID_MOBILE_NUMBER)
    .matches(/^[0-9]*$/, INVALID_MOBILE_NUMBER)
    .required(REQUIRED_MESSAGE),
  landline: Yup.string().matches(
    /^\d+$/,
    "Please enter a valid landline number"
  ),
  birthday: Yup.object({
    month: Yup.object().shape({
      value: Yup.string()
        .required(REQUIRED_MESSAGE)
        .nullable(),
    }),
    date: Yup.object().shape({
      value: Yup.number()
        .required(REQUIRED_MESSAGE)
        .nullable(),
    }),
    year: Yup.number()
      .min(1900, INVALID_YEAR)
      .max(yearNow - 18, "You must be at least 18 years old")
      .required(REQUIRED_MESSAGE),
  }),
  contactedThrough: Yup.array().min(1, REQUIRED_MESSAGE),
  prescribingDoctor: Yup.string().required(REQUIRED_MESSAGE),
  hospitalClinicName: Yup.object().shape({
    value: Yup.string()
      .required(REQUIRED_MESSAGE)
      .nullable(),
  }),
  otherHospital: Yup.string().when("hospitalClinicName", {
    is: hospitalName => hospitalName?.value === "Other",
    then: Yup.string().required(REQUIRED_MESSAGE),
  }),
  prescribedMedicine: Yup.object().shape({
    value: Yup.string()
      .required(REQUIRED_MESSAGE)
      .nullable(),
  }),
  diagnosis: Yup.object().shape({
    value: Yup.string()
      .required(REQUIRED_MESSAGE)
      .nullable(),
  }),
  otherDiagnosis: Yup.string().when("diagnosis", {
    is: diagnosis => diagnosis?.value === "Other (Please specify)",
    then: Yup.string().required(REQUIRED_MESSAGE),
  }),
  medicationStartDate: Yup.object({
    month: Yup.object().shape({
      value: Yup.string()
        .required(REQUIRED_MESSAGE)
        .nullable(),
    }),
    date: Yup.object().shape({
      value: Yup.number()
        .required(REQUIRED_MESSAGE)
        .nullable(),
    }),
    year: Yup.number()
      .min(yearNow - 5, INVALID_YEAR)
      .max(yearNow, INVALID_YEAR)
      .required(REQUIRED_MESSAGE),
  }),
  isExistingPatient: Yup.string().required(REQUIRED_MESSAGE),
  monthsAsPatient: Yup.string().when("isExistingPatient", {
    is: isExistingPatient =>
      isExistingPatient ===
      "I am an existing patient: I have already started treatment with Nintedanib (Ofev)",
    then: Yup.string().required(REQUIRED_MESSAGE),
  }),
  withConfirmedHRCT: Yup.string().required(REQUIRED_MESSAGE),
  nextAppointmentDate: Yup.object({
    month: Yup.object().shape({
      value: Yup.string()
        .required(REQUIRED_MESSAGE)
        .nullable(),
    }),
    date: Yup.object().shape({
      value: Yup.number()
        .required(REQUIRED_MESSAGE)
        .nullable(),
    }),
    year: Yup.number()
      .min(yearNow, INVALID_YEAR)
      .required(REQUIRED_MESSAGE),
  }),
})
