import * as Yup from "yup"
import {
  REQUIRED_MESSAGE,
  INVALID_EMAIL,
  INVALID_YEAR,
  INVALID_MOBILE_NUMBER,
  yearNow,
} from "services/validations"

import {
  patientDetailsSchema,
  financialDetailsSchema,
} from "./schemas/financialAssement"

import { maDeliveryValidationSchema } from "./schemas/maDeliveryDetails"
import { hopePatientEnrollmentValidation } from "./schemas/hopePatientEnrollment"

const patientEnrollmentSchema = Yup.object().shape({
  firstName: Yup.string().required(REQUIRED_MESSAGE),
  lastName: Yup.string().required(REQUIRED_MESSAGE),
  mobileNumber: Yup.string()
    .min(11, INVALID_MOBILE_NUMBER)
    .max(11, INVALID_MOBILE_NUMBER)
    .matches(/^09[0-9]*$/, INVALID_MOBILE_NUMBER)
    .required(REQUIRED_MESSAGE),
  emailAddress: Yup.string()
    .email(INVALID_EMAIL)
    .required(REQUIRED_MESSAGE),
  contactedThrough: Yup.array().min(1, REQUIRED_MESSAGE),
  prescribingDoctor: Yup.string().required(REQUIRED_MESSAGE),
  hospitalClinicName: Yup.object().shape({
    value: Yup.string()
      .required(REQUIRED_MESSAGE)
      .nullable(),
  }),
  otherHospital: Yup.string().when("hospitalClinicName", {
    is: hospitalName => hospitalName?.value === "Other",
    then: Yup.string().required(REQUIRED_MESSAGE),
  }),
  diagnosis: Yup.object().shape({
    value: Yup.string()
      .required(REQUIRED_MESSAGE)
      .nullable(),
  }),
  prescribedMedicine: Yup.object().shape({
    value: Yup.string()
      .required(REQUIRED_MESSAGE)
      .nullable(),
  }),
  otherDiagnosis: Yup.string().when("diagnosis", {
    is: diagnosis => diagnosis?.value === "Other (please specify)",
    then: Yup.string().required(REQUIRED_MESSAGE),
  }),
  isExistingPatient: Yup.string().required(REQUIRED_MESSAGE),
})

const medicineAssistanceSchema = Yup.object().shape({
  firstName: Yup.string().required(REQUIRED_MESSAGE),
  lastName: Yup.string().required(REQUIRED_MESSAGE),
  emailAddress: Yup.string()
    .email(INVALID_EMAIL)
    .required(REQUIRED_MESSAGE),
  mobileNumber: Yup.string()
    .min(11, INVALID_MOBILE_NUMBER)
    .max(11, INVALID_MOBILE_NUMBER)
    .matches(/^09[0-9]*$/, INVALID_MOBILE_NUMBER)
    .required(REQUIRED_MESSAGE),
  prescribedDosage: Yup.object().shape({
    value: Yup.string()
      .required(REQUIRED_MESSAGE)
      .nullable(),
  }),
  // dailyIntake: Yup.string().required(REQUIRED_MESSAGE),
  medicationStartDate: Yup.object({
    month: Yup.object().shape({
      value: Yup.string()
        .required(REQUIRED_MESSAGE)
        .nullable(),
    }),
    date: Yup.object().shape({
      value: Yup.number()
        .required(REQUIRED_MESSAGE)
        .nullable(),
    }),
    year: Yup.number()
      .min(1900, INVALID_YEAR)
      .max(yearNow, INVALID_YEAR)
      .required(REQUIRED_MESSAGE),
  }),
  subdistributor: Yup.object().shape({
    value: Yup.string()
      .required(REQUIRED_MESSAGE)
      .nullable(),
  }),
})

const diagnosticSubsidySchema = Yup.object().shape({
  firstName: Yup.string().required(REQUIRED_MESSAGE),
  lastName: Yup.string().required(REQUIRED_MESSAGE),
  emailAddress: Yup.string()
    .email(INVALID_EMAIL)
    .required(REQUIRED_MESSAGE),
  mobileNumber: Yup.string()
    .min(11, INVALID_MOBILE_NUMBER)
    .max(11, INVALID_MOBILE_NUMBER)
    .matches(/^09[0-9]*$/, INVALID_MOBILE_NUMBER)
    .required(REQUIRED_MESSAGE),
  birthday: Yup.object({
    month: Yup.object().shape({
      value: Yup.string()
        .required(REQUIRED_MESSAGE)
        .nullable(),
    }),
    date: Yup.object().shape({
      value: Yup.number()
        .required(REQUIRED_MESSAGE)
        .nullable(),
    }),
    year: Yup.number()
      .min(1900, INVALID_YEAR)
      .max(yearNow - 18, "You must be at least 18 years old")
      .required(REQUIRED_MESSAGE),
  }),
  prescribingDoctor: Yup.string().required(REQUIRED_MESSAGE),
  hospitalClinicName: Yup.object().shape({
    value: Yup.string()
      .required(REQUIRED_MESSAGE)
      .nullable(),
  }),
  otherHospital: Yup.string().when("hospitalClinicName", {
    is: hospitalName => hospitalName?.value === "Other",
    then: Yup.string().required(REQUIRED_MESSAGE),
  }),
  partnerLaboratory: Yup.string().required(REQUIRED_MESSAGE),
})

export const getValidationSchema = module => {
  switch (module) {
    case "patient":
      return patientEnrollmentSchema
    case "hope-diagnostic-subsidy":
      return diagnosticSubsidySchema
    case "hope-patient":
      return hopePatientEnrollmentValidation
    case "medicine-assistance":
      return medicineAssistanceSchema
    case "financial-assessment-personal":
      return patientDetailsSchema
    case "financial-assessment-financial":
      return financialDetailsSchema
    case "medicine-availment":
      return maDeliveryValidationSchema
  }
}
