import React, { useContext, Fragment } from "react"

import Dropzone from "elements/Dropzone"
import DocumentsCardRow from "elements/Dropzone/DocumentsCardRow"

import { AppContext } from "../../context/AppContext"

const UploadPrescriptionSection = ({ docType, icon, label }) => {
  const { state, dispatch } = useContext(AppContext)

  const handleUploadSuccess = ({ file }) => {
    dispatch({
      type: "SAVE_DOCUMENT",
      payload: file,
    })
  }

  const handleDeleteDocument = index => {
    let tempDocuments = [...state?.documents]
    tempDocuments.splice(index, 1)
    dispatch({
      type: "SAVE_DOCUMENTS",
      payload: [...tempDocuments],
    })
  }

  return (
    <Fragment>
      <div className="mb-1">
        {state?.documents?.length > 0 &&
          state?.documents?.map((document, index) => (
            <DocumentsCardRow
              index={index}
              fileName={document.name}
              oldFileName={document.oldname}
              handleDeleteDocument={handleDeleteDocument}
              file={document}
            />
          ))}
      </div>
      {state?.documents?.length < 6 && (
        <Dropzone
          currentFilesList={state?.documents}
          docType={docType || "RX"}
          icon={icon}
          label={label}
          onUploadSuccess={handleUploadSuccess}
          maxFileCount={6}
        />
      )}
    </Fragment>
  )
}

export default UploadPrescriptionSection
