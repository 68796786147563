import * as Yup from "yup"

import {
  REQUIRED_MESSAGE,
  INVALID_EMAIL,
  INVALID_MOBILE_NUMBER,
} from "services/validations"

export const maDeliveryValidationSchema = Yup.object().shape({
  firstName: Yup.string().required(REQUIRED_MESSAGE),
  lastName: Yup.string().required(REQUIRED_MESSAGE),
  emailAddress: Yup.string()
    .email(INVALID_EMAIL)
    .required(REQUIRED_MESSAGE),
  mobileNumber: Yup.string()
    .min(11, INVALID_MOBILE_NUMBER)
    .max(11, INVALID_MOBILE_NUMBER)
    .matches(/^09[0-9]{9}$/, INVALID_MOBILE_NUMBER)
    .required(REQUIRED_MESSAGE),
  secondaryContactPerson: Yup.string().required(REQUIRED_MESSAGE),
  secondaryContactNumber: Yup.string()
    .min(11, INVALID_MOBILE_NUMBER)
    .max(11, INVALID_MOBILE_NUMBER)
    .matches(/^09[0-9]{9}$/, INVALID_MOBILE_NUMBER)
    .required(REQUIRED_MESSAGE),
  deliveryAddress: Yup.object().shape({
    addressType: Yup.string().required(REQUIRED_MESSAGE),
    streetAddress: Yup.string().required(REQUIRED_MESSAGE),
    province: Yup.object().shape({
      value: Yup.string().required(REQUIRED_MESSAGE),
    }),
    city: Yup.object().shape({
      value: Yup.string().required(REQUIRED_MESSAGE),
    }),
  }),
})
