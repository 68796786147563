import React, { useContext, useEffect, Fragment } from "react"
import { Formik, Form } from "formik"
import { navigate } from "gatsby"

import Layout from "../Layout/Layout"
import FormAddons from "./FormAddons"
import Message from "elements/Message"
import Section from "elements/Section"
import Container from "layout/Container"
import ActionButtons from "elements/ActionButtons"
import UploadGuidelines from "elements/UploadGuidelines"
import UploadPrescription from "elements/UploadPrescription"

import ProgressBar from "elements/ProgressBar"
import { getEncryptedKey } from "./services/keys"
import { AppContext } from "../../context/AppContext"
import { parseFormInitialState } from "./services/values"
import { generateFormField } from "elements/Form/services/form"
import { getValidationSchema } from "./utils/formValidationSchema"

import { isFutureDate } from "../../services/general"

const checkFileUploads = (docTypes, state) => {
  for (let index in docTypes) {
    let documentCount = state?.documents.filter(document =>
      document.name.startsWith(docTypes[index])
    ).length
    if (documentCount === 0) return true
  }
  return false
}

const FormContainer = props => {
  const { state, dispatch } = useContext(AppContext)

  const {
    module,
    nextPath,
    backPath,
    enrollmentForm,
    enrollmentIntialState,
  } = props.pageContext
  const nextLink = nextPath

  let formInitialState = parseFormInitialState({
    state,
    module: module.stateKey,
    enrollmentIntialState,
  })

  useEffect(() => {
    if (getEncryptedKey(`${module.app}-${module.name}`)) {
      navigate(backPath)
    }
  }, [backPath])

  const handleFormSubmit = (values, { setErrors }) => {
    let formErrors = {}

    if (module.stateKey === "hope-patient") {
      let { medicationStartDate, diagnosisDate, nextAppointmentDate } = values

      if (isFutureDate(medicationStartDate)) {
        formErrors.medicationStartDate = {
          month: { value: "Please enter a valid medication date." },
          date: { value: "Please enter a valid medication date." },
        }
      }

      if (isFutureDate(diagnosisDate)) {
        formErrors.diagnosisDate = {
          month: { value: "Please enter a valid diagnosis date." },
          date: { value: "Please enter a valid diagnosis date." },
        }
      }

      if (!isFutureDate(nextAppointmentDate)) {
        formErrors.nextAppointmentDate = {
          month: { value: "Please enter a valid next appointment date." },
          date: { value: "Please enter a valid next appointment date." },
        }
      }
    }

    if (module.name === "patient") {
      let { diagnosisDate } = values

      if (isFutureDate(diagnosisDate)) {
        formErrors.diagnosisDate = {
          month: { value: "Please enter a valid diagnosis date." },
          date: { value: "Please enter a valid diagnosis date." },
        }
      }
    }

    if (module.name === "medicine-assistance") {
      let { medicationStartDate } = values

      if (isFutureDate(medicationStartDate)) {
        formErrors.medicationStartDate = {
          month: { value: "Please enter a valid medication start date." },
          date: { value: "Please enter a valid medication start date." },
        }
      }
    }

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors)
    } else {
      let summaryPayload = { ...values }

      if (module?.dispatch === "SAVE_MEDICINE_ASSISTANCE")
        summaryPayload.existingUserMAConsent =
          state?.medicineAssistance?.existingUserMAConsent

      if (module?.dispatch)
        dispatch({
          type: module.dispatch,
          payload: summaryPayload,
        })
      navigate(nextLink)
    }
  }

  const shouldFormBeDisabled = ({ module }) => {
    let moduleName = module.name
    if (module.addon === "financial-assessment-financial")
      moduleName = "financial-assessment-financial"

    switch (moduleName) {
      case "financial-assessment-financial":
        return state?.documents?.length === 0
      case "medicine-assistance":
        return !state?.medicineAssistance?.existingUserMAConsent?.length > 0
      case "patient":
        return checkFileUploads(["id", "rx"], state)
      default:
        return module.hasUpload && state?.documents?.length === 0
    }
  }

  return (
    <Layout
      title={module?.title}
      subtitle={module?.subtitle}
      seoTitle={`${module.seoTitle}`}
    >
      <Container isCentered>
        {module?.progressBar && <ProgressBar data={module?.progressBar} />}
        <Formik
          initialValues={formInitialState}
          validationSchema={getValidationSchema(module.stateKey)}
          onSubmit={handleFormSubmit}
        >
          {({ values, setFieldValue, isValid, submitCount, errors }) => (
            <Form>
              {enrollmentForm.map(section => (
                <Fragment>
                  {section?.section ===
                    "Alternative Funding Support or Financial Assistance Availed" && (
                    <FormAddons addon={`${module.addon}-addon`} />
                  )}
                  <Section
                    title={section?.section}
                    id={section?.id}
                    subtitle={section?.subtitle}
                    isRequired
                    sectionHelper={section?.sectionHelper}
                  >
                    {section?.fields.map(field => {
                      if (!field?.referenceAnswer) {
                        return generateFormField({
                          formFields: section?.fields,
                          formField: field,
                          values,
                          setFieldValue,
                        })
                      }
                      return null
                    })}
                    {module.app === "giotrif" &&
                      module.name === "patient" &&
                      section?.section === "Prescription Details" && (
                        <FormAddons
                          values={values}
                          addon={`${module.addon}-prescription`}
                        />
                      )}
                  </Section>
                </Fragment>
              ))}
              {module.hasUpload && (
                <Section
                  id="upload-prescription"
                  title="Upload Documents"
                  isRequired={true}
                >
                  {module?.prescriptionMessage && (
                    <Message>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: module?.prescriptionMessage,
                        }}
                      />
                    </Message>
                  )}
                  <UploadGuidelines />
                  <UploadPrescription label={module?.prescriptionLabel} />
                  {submitCount > 0 && state.documents.length < 1 && (
                    <p className="help mt-0 mb-1 is-danger">
                      Please upload your prescription to proceed
                    </p>
                  )}
                </Section>
              )}
              <FormAddons values={values} addon={module.addon} />
              {!isValid && submitCount > 0 && (
                <Message color="danger">
                  You may have missed some required fields. Please scan through
                  the form and check if your information is complete.
                </Message>
              )}
              <ActionButtons
                back={{ label: "Back", link: backPath }}
                submit={{
                  label: "Next",
                  disabled: shouldFormBeDisabled({ module }),
                }}
              />
            </Form>
          )}
        </Formik>
      </Container>
    </Layout>
  )
}

export default FormContainer
